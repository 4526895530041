@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --size: 12px;
}

.light {
  --background: #FAFBFC;
  --backgroundAlt: #FFFFFF;
  --foreground: #000000;
  --foregroundSecondary: #686869;
  --elementBackgroundSecondary: #F6F7F8;
  --color: #24292e;
  --contrast: 00, 00, 00;
  --elementBlue: #367AF7;
}

.dark {
  --background: #232B33;
  --backgroundAlt: #333A42;
  --foreground: #FFFFFF;
  --foregroundSecondary: #C2C9CF;
  --elementBackgroundSecondary: #232B33;
  --color: #24292e;
  --contrast: 255, 255, 255;
  --elementBlue: #4781C1;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../fonts/HelveticaNowDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("../fonts/HelveticaNowDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  /*font-family: "Helvetica Now Display", sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

.rc-border-b-2, .rc-border-l-2, .rc-border-t-2 {
  border-color: rgba(var(--contrast), 0.1) !important;
}

.rc-bg-white {
 background-color: var(--backgroundAlt);
}

button.rc-cursor-pointer {
  padding: 6px 12px;
  background-color: var(--backgroundAlt);
  color: var(--foregroundSecondary);
  font-size: 12px;
  border: 1px solid var(--elementBlue);
  border-radius: 6px;
}

button.rc-cursor-pointer:hover {
    background-color: rgba(var(--contrast), 0.05);
}
button.rc-cursor-pointer:active {
    background-color: var(--elementBlue);
    color: var(--background);
}

div.rc-ml-4.rc-mr-4.rc-w-32.rc-text-center {
  background-color: var(--elementBackgroundSecondary);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-inline: 12px;
}

.rc-divide-gray-200.rc-divide-y.rc-overflow-hidden.rc-max-h-36.rc-overflow-y-auto > div {
  border-color: blue !important;
}

.rc-flex.rc-justify-end.rc-mb-4 {
    margin-bottom: 0;
}