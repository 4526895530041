// .studium_ui_container {
//   height: 100%;
//   // display: flex;
//   // align-items: flex-start;
//   width: max-content;
//   align-self: stretch;
//   max-width: 1080px;
//   margin-inline: auto;
// }

.studium_ui_container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
}

// @media (min-width: 768px) {
//   .studium_ui_container {
//     width: 750px;
//   }
// }

// @media (min-width: 992px) {
//   .studium_ui_container {
//     width: 970px;
//   }
// }

// @media (min-width: 1200px) {
//   .studium_ui_container {
//     width: 1170px;
//   }
// }