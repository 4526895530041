.main_layout_header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  margin-top: 48px;
  height: 36px;
  gap: 12px;
  flex-shrink: 0;
  justify-content: space-between;
}

.main_layout_header_title {
  font-family: 'Helvetica Now Display';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  color: #000000;
}

.main_layout_header_left {
  display: flex;
  gap: 12px;
  align-items: center;
}

.main_layout_header_right {
  display: flex;
  gap: 12px;
  align-items: center;
}

.main_layout_header_username_container {
  display: flex;
  flex-direction: column;
}

.main_layout_header_username_description {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.main_layout_header_user {
  height: 36px;
  width: 36px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

/* .main_layout_header_ {} */